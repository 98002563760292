import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FlowService, WorkPlatformService } from '@/sevices';
import { isPC, isPhone } from '@/common/utils';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { CTable } from '@cloudpivot-hermes/common-components';
const Global = namespace('Global');
let FlowInstance = class FlowInstance extends Vue {
    constructor() {
        super(...arguments);
        this.isShowFilter = false;
        this.tabValue = 'null';
        this.searchParams = {};
        this.sourceType = {
            0: '工作台/运营后台',
            1: '开发平台/PASS平台',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
            },
            {
                width: 250,
                dataIndex: 'instanceName',
                key: 'instanceName',
                ellipsis: true,
                slots: { title: 'instanceNameTitle', name: '流程名称' },
            },
            {
                width: 200,
                dataIndex: 'workflowName',
                key: 'workflowName',
                ellipsis: true,
                slots: { title: 'workflowNameTitle', name: '流程模板' },
            },
            {
                width: 250,
                dataIndex: 'participantNames',
                key: 'participantNames',
                ellipsis: true,
                slots: { title: 'participantNamesTitle', name: '流程处理人' },
            },
            {
                width: 200,
                dataIndex: 'startTime',
                key: 'startTime',
                ellipsis: true,
                slots: { title: 'startTimeTitle', name: '发起时间' },
            },
            {
                width: 250,
                dataIndex: 'usedTime',
                key: 'usedTime',
                ellipsis: true,
                slots: { title: 'usedTimeTitle', name: '已用时' },
            },
            {
                dataIndex: 'originatorName',
                key: 'originatorName',
                ellipsis: true,
                slots: { title: 'originatorNameTitle', name: '发起人' },
            },
            {
                width: 80,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                fixed: 'right',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    showFilter() {
        this.isShowFilter = true;
    }
    closeFilter() {
        this.isShowFilter = false;
    }
    get IsDingTalk() {
        return sessionStorage.IS_DINGTALK === 'true';
    }
    get isFreeLoginPlatform() {
        return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
    }
    get ISWECHAT() {
        return sessionStorage.IS_WECHAT === 'true';
    }
    async handler(item) {
        const params = {
            appCode: item.appCode,
            appVersion: item.appVersion,
        };
        const res = await WorkPlatformService.getAppUrl(params);
        if (res.success) {
            const pageJsonRes = await FlowService.getPageJson({
                appCode: item.appCode,
                workflowInstanceId: item.id,
            });
            if (!pageJsonRes.success)
                return;
            const winOpen = !this.isFreeLoginPlatform && window.open('', '_blank');
            const workItemData = pageJsonRes.data || {};
            const pageArr = workItemData.boundPages || [];
            let pageJson = {};
            for (const page of pageArr) {
                // pageType:ALL,PC,MOBILE
                if (page.pageType !== 'ALL') {
                    if (isPC() && page.pageType === 'PC') {
                        pageJson = page;
                        break;
                    }
                    else if (isPhone() && page.pageType === 'MOBILE') {
                        pageJson = page;
                        break;
                    }
                    else {
                        pageJson = page;
                    }
                }
                else {
                    pageJson = page;
                    break;
                }
            }
            const query = {
                appCode: '',
                groupCode: '',
                pageGroupCode: '',
                pageCode: '',
                workItemId: workItemData.workItemId,
                workflowInstanceId: workItemData.workflowInstanceId,
                appVersion: item.appVersion,
                workflowCode: item.workflowCode,
                ...pageJson,
            };
            const str = Object.entries(query)
                .map((queryArr) => {
                return queryArr.join('=');
            })
                .join('&');
            let url = `${res.data}?${str}`;
            if (process.env.NODE_ENV !== 'production') {
                url = `http://localhost${res.data.substring(res.data.indexOf('/api/launcher/'))}?${str}`;
            }
            try {
                if (this.ISWECHAT && isPhone()) {
                    const token = localStorage.getItem('token');
                    const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                    window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                }
                else if ((this.IsDingTalk || this.ISWECHAT)) {
                    const token = localStorage.getItem('token');
                    const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                    if (this.needOpenBrowser) {
                        service.openLink(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                    else {
                        window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                }
                else {
                    if (!winOpen) {
                        throw new Error('浏览器新开弹窗未开启');
                    }
                    setTimeout(() => {
                        winOpen.location = url;
                    }, 100);
                }
            }
            catch (e) {
                Vue.prototype.$errorcatcher.showMessagePopup({
                    title: '错误提示！',
                    message: [e, '!'],
                    displayFooter: false,
                    correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
                });
            }
            // window.location.href = url;
        }
    }
    onChangeTab() {
        this.filterFlowInstance.reset();
        this.search();
    }
    /**
     * 搜索
     */
    search(searchParams = {}) {
        this.searchParams = searchParams;
        this.ctable.pagination.current = 1;
        this.ctable.pagination.pageSize = 20;
        this.isShowFilter = false;
        this.ctable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            ...this.searchParams,
            state: this.tabValue === 'null' ? '' : this.tabValue,
            page: params.page - 1,
        };
        const res = await FlowService.getFlowInstanceList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.usedTime = this.formatSeconds(item.usedTime);
            });
        }
        return res;
    }
    formatSeconds(value) {
        if (!value)
            return '--';
        let theTime = parseInt(value); // 需要转换的时间秒
        let theTime1 = 0; // 分
        let theTime2 = 0; // 小时
        let theTime3 = 0; // 天
        theTime = theTime / 1000;
        if (theTime > 60) {
            theTime1 = parseInt(String(theTime / 60));
            theTime = parseInt(String(theTime % 60));
            if (theTime1 > 60) {
                theTime2 = parseInt(String(theTime1 / 60));
                theTime1 = parseInt(String(theTime1 % 60));
                if (theTime2 > 24) {
                    // 大于24小时
                    theTime3 = parseInt(String(theTime2 / 24));
                    theTime2 = parseInt(String(theTime2 % 24));
                }
            }
        }
        let result = '';
        if (theTime > 0) {
            result = '' + parseInt(theTime + '') + '秒';
        }
        if (theTime1 > 0) {
            result = '' + parseInt(theTime1 + '') + '分' + result;
        }
        if (theTime2 > 0) {
            result = '' + parseInt(theTime2 + '') + '小时' + result;
        }
        if (theTime3 > 0) {
            result = '' + parseInt(theTime3 + '') + '天' + result;
        }
        return result;
    }
};
__decorate([
    Ref()
], FlowInstance.prototype, "ctable", void 0);
__decorate([
    Ref()
], FlowInstance.prototype, "filterFlowInstance", void 0);
__decorate([
    Global.State('needOpenBrowser')
], FlowInstance.prototype, "needOpenBrowser", void 0);
FlowInstance = __decorate([
    Component({
        name: 'FlowInstance',
        components: {
            CTable,
            FilterDrawer: () => import('@/components/flow-manage/filter-drawer'),
            FilterFlowInstance: () => import('@/components/flow-manage/filter-drawer/filter-flowInstance.vue'),
        },
    })
], FlowInstance);
export default FlowInstance;
